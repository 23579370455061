<template>
    <div v-if="dialog" class="relative z-50">
        <div class="fixed inset-0 bg-black bg-opacity-80"></div>
        <div class="fixed inset-0 overflow-auto">
            <div class="flex items-center justify-center h-full">
                <div class="bg-white rounded-lg p-4 w-full max-w-xs">
                    <div>
                        <div class="text-lg font-bold">PIN TripwePay</div>
                        <v-otp-input
                            v-show="pin.visible"
                            ref="pinInput"
                            class="flex justify-between mt-4"
                            :num-inputs="6"
                            separator=""
                            :input-type="pin.type"
                            autocomplete="off"
                            input-classes="w-10 h-10 border border-gray-400 rounded-lg text-center"
                            @on-change="setPin"
                        />
                    </div>
                    <div class="flex mt-8 justify-end">
                        <button
                            @click="cancel"
                            class="bg-gray-200 hover:bg-gray-300 disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-default font-semibold py-2 px-6 rounded-lg"
                        >
                            Cancel
                        </button>
                        <button
                            @click="confirm"
                            :disabled="isInvalidPin"
                            class="bg-primary text-white hover:bg-primary-darker disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-default font-semibold py-2 px-6 rounded-lg ml-4"
                        >
                            {{ button }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialog: false,
                pin: { value: '', visible: true, type: 'number' },
                button: ''
            };
        },

        computed: {
            isInvalidPin() {
                return this.pin.value.toString().length != 6;
            }
        },

        methods: {
            open({ button }) {
                this.button = button ?? 'OK';
                this.pin = { value: '', visible: true, type: 'number' };
                this.dialog = true;

                return new Promise((resolve, reject) => {
                    this.resolvePromise = resolve;
                    this.rejectPromise = reject;
                });
            },
            async confirm() {
                this.pin.visible = false;
                this.pin.type = 'number';
                this.resolvePromise(this.pin.value);
                this.dialog = false;
            },
            async cancel() {
                this.resolvePromise(null);
                this.dialog = false;
            },
            setPin(val) {
                this.pin.type = 'password';
                this.pin.value = val;
            }
        }
    };
</script>
